import navUtil from "@/components/global/CustomizeNav/util";
import {
  getResuList,
  delLine,
  publishThis,
  stopInfoThis,
  openHistory,
  batchStop,
  batchPublish,
  batchDeleteById,
  download,
  importSearch,
  importSumbit,
  getResuListFile
} from "./api";
import axios from "axios";
import { publicPath } from "@/common/constant.js";
import Util from "@/utils/utils";
export default {
  data() {
    return {
      dataList: [],
      navList: [],
      isChange: false,
      breadcrumbData: [{
          path: "/index",
          name: "index",
          title: "首页"
        },

        {
          path: "/productChannelWholesalePrice/productChannelPriceList",
          name: "productChannelPriceList",
          title: "渠道批发价(产品)"
        }
      ],
      total: 0,
    
      tcolumns: [{
          label: "分公司",
          prop: "orgName",
          width: 200
        },
        {
          label: "物料组",
          prop: "matklName",
          width: 250
        },
        {
          label: "型号",
          prop: "productModelName",
          width: 200
        },
        {
          label: "渠道批发价",
          prop:"channelPrice",
          key: "channelPrice",
          align: 'right',
          ellipsis: true
        },
        {
          label: "零售价",
          prop: "retailPrice",
          key: "retailPrice",
          align: 'right',
          ellipsis: true
        },
        {
          label: "状态",
          prop: "status",
          key: "status",
          align: 'right',
          ellipsis: true
        },
        {
          label: "开始时间",
          prop: "startDate",
          width: 200
        },
        {
          label: "结束时间",
          prop: "endDate",
          width: 200
        },
        {
          label: "下发时间",
          prop: "issueDate",
          key: "issueDate",
          align: 'right',
          ellipsis: true
        },
        {
          label: "操作",
          prop: "",
          width:300,
          key: "borderedQty",
          slot: "btnGroup"
        }
      ],

      tableData: [],
      tabelIsLoading: false,
      //x 最大宽度 y 最大高度
      scroll: {
        x: 3400
      },

      pager: {
        pageNo: 1,
        pageSize: 10,
        count: 0,
      },
      loading: false,
      params: {
       
      },
      selRows: [],
      expLoading: false,
      rowDta:'',
      visibleInfo:false,
      visiblePublish:false,
      visibleStop:false,
      modelShow:false,
      data:[],
      pager1: {
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      pager2:{
        pageNo: 1,
        pageSize: 10,
        count: 10
      },
      dataAll:[],
      dataAll2:[],
      // 查看价格单历史
      columns: [{
          title: '分公司',
          dataIndex: 'orgName',
          key: 'orgName',
        },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'matklName'
        },
        {
          title: '型号',
          dataIndex: 'productModelName',
          key: 'productModelName',
          width:300
        },
        {
          title: '渠道批发价',
          dataIndex: 'channelPrice',
          key: 'channelPrice'
        },
        {
          title: '零售价',
          dataIndex: 'retailPrice',
          key: 'retailPrice'
        },
        {
          title: '更新时间',
          dataIndex: 'createdTime',
          key: 'createdTime',
          width: 250
        }],
      // 导入数据
      columnsimport: [{
        title: '销售组织',
        dataIndex: 'orgName',
        key: 'orgName',
      },
        {
          title: '物料组',
          dataIndex: 'matklName',
          key: 'matklName'
        },
        {
          title: '产品型号',
          dataIndex: 'zzprdmodel',
          key: 'zzprdmodel',
          width:300,
        },
        {
          title: '开始时间',
          dataIndex: 'startDate',
          key: 'startDate'
        },
        {
          title: '结束时间',
          dataIndex: 'endDate',
          key: 'endDate'
        },
        {
          title: '渠道批发价',
          dataIndex: 'channelPrice',
          key: 'channelPrice'
        },
        {
          title: '零售价',
          dataIndex: 'retailPrice',
          key: 'retailPrice'
        },
        {
          title: '校验结果',
          dataIndex: 'checkRemarks',
          key: 'checkRemarks',
          width: 250
        }],
      pageLoadFlagTable:false,
      importFileModel:false,
      importTableData:[],
      fileList:[],
      jiaoyanloading:false
    };

  },
  mounted() {
    //导航数据
    this.navList = navUtil.orderList;
    // this.columns = tableUtil.columns;
    this.getData();
  },
  methods: {
    // 下载模板
    downFile(){
      let data  = {

      }

      download(data).then(res => {
        this.isLoading = false;
        Util.blobToJson(res.data).then(content => {
          if (content && content.msg == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '产品渠道价格模板.xls')
        }).finally(() => {
          this.expLoading = false;
        })
      })
    },
    // 导入数据
    importFile() {
      this.importFileModel = true
    },

    handleRemove(file) {
      const index = file;
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },
    beforeUpload(file) {
      this.pageLoadFlagTable = true
      if( (file.size)/1024  > 5120 ) {
        this.$message.warning('附件大小不能大于5M')
        return false;
      } else {
        //    调接口

        let formData = new FormData()
        formData.append('file',file);
        const BASE_API = publicPath;
        let config = {
          headers: { 'content-type': 'multipart/form-data',},
        }
        axios.post(BASE_API + '/channelPriceProductFile/uploadFile.nd' , formData, config).then(res => {
          let  resResult = res.data
          if(resResult.code == "0") {
            this.fileList = [...this.fileList, file]
            this.fileList = this.fileList.slice(-1);
            this.fileList.forEach(item=>{
              item.size2 = (( item.size) / 1024).toFixed(2)
            })
            // 附件上传成功以后查询数据
            importSearch({}).then(res=>{
              if(res.data.code == '0'){
                this.importTableData = res.data.list
                this.dataAll2 = res.data.list
                this.importTableData = res.data.list.slice(0, 10)
                this.pager2.count = res.data.list.length;
              } else {
                this.$message.warning(res.data.msg)
              }
            })
          } else {
            this.$message.warning(resResult.msg,3)
          }
          this.pageLoadFlagTable = false
        })

        return false;
      }
    },
    // 附件更改
    handleChange({fileList}) {
      let fileList1 = fileList;
      this.fileList1 = fileList1.slice(-1);
      this.fileList.forEach(item=>{
        item.size2 = (( item.size) / 1024).toFixed(2)
      })
      this.dataAll2 = []
      this.importTableData = []

    },
    // 提交验证成功数据
    sumbitFile(){
      this.jiaoyanloading = true
      this.tabelIsLoading = true
      importSumbit({}).then(res=>{
        if(res.data.code == 0) {
          this.$message.success('提交成功',3)
          this.getData()
          this.tabelIsLoading = false
          this.importFileModel = false
          this.jiaoyanloading = false
        } else {
          this.$message.warning(res.data.msg,3)
           this.getData()
          this.tabelIsLoading = false
          this.jiaoyanloading = false
        }
      })
    },

    rowSelection(rows) {
      this.selRows = rows
    },
    // 批量暂停
    batchPause() {
      let ids = []
      this.selRows.forEach (it => {
        ids.push(it.id)
      });
      if( this.selRows.length <= 0 ) {
        this.$message.warning('请选择至少一条数据')
        return
      } else {
        this.tabelIsLoading = true
        let data  = {
          ids:ids.join(',')
        }
        batchStop(data).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('暂停成功')
            this.tabelIsLoading = false
            this.getData()
          } else {
            this.$message.warning(res.data.msg)
            this.tabelIsLoading = false
          }
        })
      }
    },
    // 批量删除
    batchDelet() {
      let ids = []
      this.selRows.forEach (it => {
        ids.push(it.id)
      });
      if( this.selRows.length <= 0 ) {
        this.$message.warning('请选择至少一条数据')
        return
      } else {
        this.tabelIsLoading = true
        let data  = {
          ids:ids.join(',')
        }
        batchDeleteById(data).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('删除成功')
            this.tabelIsLoading = false
            this.getData()
          } else {
            this.$message.warning(res.data.msg)
            this.tabelIsLoading = false
          }
        })
      }
    },
    // 批量发布
    batchRelease() {
      let ids = []
      this.selRows.forEach (it => {
        ids.push(it.id)
      });
      if( this.selRows.length <= 0 ) {
        this.$message.warning('请选择至少一条数据')
        return
      } else {
        this.tabelIsLoading = true
        let data  = {
          ids:ids.join(',')
        }
        batchPublish(data).then(res=>{
          if(res.data.code == 0) {
            this.$message.success('发布成功')
            this.tabelIsLoading = false
            this.getData()
          } else {
            this.$message.warning(res.data.msg)
            this.tabelIsLoading = false
          }
        })
      }
    },

    handleOk(e) {
      this.pageLoadFlagTable = false;
    },
    // 查看历史
    historyView(row){
      let data  = {
        id:row.id
      }
      openHistory(data).then(res=>{
        if( res.data.list ) {
          this.pageLoadFlagTable = false;
          this.dataAll = res.data.list;
          this.data = res.data.list.slice(0, 10)
          this.pager1.count = res.data.list.length;
        } else {
          this.$message.warning('获取列表失败')
        }
      })
      this.modelShow = true
    },
    // 分页
    pagesiezes(data) {
      // var prev = data - 1;
      var start = (data - 1) * this.pager1.pageSize;
      this.data = this.dataAll.slice(start, start + this.pager1.pageSize)
      this.pager1.pageSize;
    },
    pagesiezes2(importTableData) {
      // var prev = data - 1;
      var start = (importTableData - 1) * this.pager2.pageSize;
      this.importTableData = this.dataAll2.slice(start, start + this.pager2.pageSize)
      this.pager2.pageSize;
    },
    // 暂停
    stop(row) {
      this.rowDta = ''
      this.visibleStop = true
      this.rowDta = row.id
    },
    stopInfo() {
      let data = {
        id:this.rowDta
      }
      stopInfoThis(data).then(res=> {
        if(res.data.code == 0 ) {
          this.getData()
        } else {
          this.$message.warning试 (res.data.msg)
        }
      })
    },
    // 新增
    jump(type) {
      if(type == 'add') {
        let routeUrl = this.$router.resolve({
          path: "/productChannelWholesalePrice/productChannelPriceadd",
          query: {
            type: type,
            // id:row.id
          },
        });
        window.open(routeUrl.href, "_blank");
      }

    },
    // 编辑
    edit(row){
      let routeUrl = this.$router.resolve({
        path: "/productChannelWholesalePrice/productChannelPriceadd",
        query: {
          type: 'edit',
          id:row.id
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 发布数据
    publish(row) {
      this.rowDta = ''
      this.visiblePublish = true
      this.rowDta = row.id
    },
    publishInfo() {
      let data = {
        id:this.rowDta
      }
      publishThis(data).then(res=> {
        if(res.data.code == 0 ) {
          this.getData()
        } else {
          this.$message.warning (res.data.msg)
        }
      })
    },

    // 删除
    del(row) {
      this.rowDta = ''
      this.visibleInfo = true
      this.rowDta = row.id
    },
    delect() {
      let data = {
        id:this.rowDta
      }
      delLine(data).then(res=> {
        if(res.data.code == 0 ) {
          this.getData()
        } else {
          this.$message.warning (res.data.msg)
        }
      })
    },

    // 判断什么条件不能点击
    checkSelectable(row) {
      // return row.actPro.status == 2
    },
    downloadFile(blob, name) {
      var reader = new FileReader();
      reader.readAsDataURL(blob); // 转换为base64，可以直接放入a表情href
      reader.onload = function (e) {
        // 转换完成，创建一个a标签用于下载
        var a = document.createElement("a");
        a.download = name;
        a.href = e.target.result;
        $("body").append(a); // 修复firefox中无法触发click
        a.click();
        $(a).remove();
      }
    },
    exportFile() {
      this.expLoading = true;
      let data = {
        pageNo: 1,
        pageSize: 300000,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        ...this.params
      };
      getResuListFile(data).then(res => {
        Util.blobToJson(res.data).then(content => {
          if (content && content.code == 'success') {
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err => {
          Util.downloadFile(res.data, '渠道批发价(产品).xls')
        }).finally(() => {
          this.expLoading = false;
        })
      }).catch(err => {
        this.expLoading = false;
      });
    },
    
    // 获取列表数据
    getData() {
      let data =  this.params
      data.pageNo = this.pager.pageNo;
      data.pageSize = this.pager.pageSize;
      this.tabelIsLoading = true;
      getResuList(data).then(res=>{
        this.tabelIsLoading = false;
        this.tableData = res.data.list;
        this.pager.pageNo = res.data.currentPage
        this.pager.count = res.data.totalRows;
      })
    },
    // 点击查询
    pageSearch(pager) {
      this.pager.pageNo = pager.pageNo;
      this.pager.pageSize = pager.pageSize;
      this.getData();
    },
   
    // 点击搜索进行数据查询
    searchFun(arg) {
      this.params = {
        ...this.pager.pageNo,
        ...this.pager.pageSize,
        ...this.params,
        ...arg[0]
      };
      this.getData();
    }
  }
};