import http from "@/utils/request";
export function getResuList(data) {
    return http({
      method: "get",
      url: "/channelPriceProduct/productBillList.nd",
      params:data
    });
  }
  // 导出
export function getResuListFile(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/exportData.nd",
    params:data,
    responseType: 'blob'
  });
}
// 删除单行

export function delLine(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/deleteById.nd",
    params:data
  });
}

// publishThis发布单挑信息

export function publishThis(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/publish.nd",
    params:data
  });
}

// 暂停

export function stopInfoThis(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/stop.nd",
    params:data
  });
}

// 查看历史
export function openHistory(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/viewHistory.nd",
    params:data
  });
}

// 批量暂停
export function batchStop(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/batchStop.nd",
    params:data
  });
}
// 批量发布
export function batchPublish(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/batchPublish.nd",
    params:data
  });
}

// 批量删除
export function batchDeleteById(data) {
  return http({
    method: "get",
    url: "/channelPriceProduct/batchDeleteById.nd",
    params:data
  });
}
// 下载模板
export function download(data) {
  return http({
    method: "post",
    url: "/upload/priceProudct.xls",
    data,
    responseType: 'blob'
  });
}
// 导入以后查询
export function importSearch(data) {
  return http({
    method: "get",
    url: "/channelPriceProductFile/priceProductFileInit.nd",
    params:data
  });
}
// 导入提交
export function importSumbit(data) {
  return http({
    method: "post",
    url: "/channelPriceProductFile/priceProductFileSubmit.nd",
    data
  });
}